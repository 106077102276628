import type { TagResourceResponse, TagEachResource } from '@/lib/api'
import { useServices } from '@/lib/services'
import { ResourceTypes, TagResourceService } from '@/lib/api'
import { reactive } from 'vue'

export class TagManager {
  public readonly tags: TagEachResource[] = reactive([])
  protected resource!: TagResourceResponse
  protected readonly services: TagResourceService

  /**
   * Initializes a new instance of the TagManager with a specified remote ID and resource type.
   * @param remoteId The unique identifier for the remote resource.
   * @param type The type of the resource (e.g., document, image).
   */
  private constructor(public readonly remoteId: string = '', public readonly type: ResourceTypes) {
    this.services = useServices().tagManager.resource
    this.load()
  }

  static async setup(remoteId: string, type: ResourceTypes) {
    const tagManager = new TagManager(remoteId, type)
    await tagManager.load()
    return tagManager
  }

  /**
   * Loads the resource's tags from the service and updates the local tags array.
   * Fetches the resource details including its tags, clears the current tags array, and populates it with the fetched tags.
   */
  public async load(force: boolean = false): Promise<void> {
    if (this.tags.length === 0 || force) {
      this.resource = await this.services
        .findResource({
          remoteId: this.remoteId,
          type: this.type
        })
        .then((tags) => tags[0])
      this.tags.splice(0, this.tags.length)
      if (this.resource && this.resource.tags) this.tags.push(...this.resource.tags)
    }
  }

  /**
   * Assigns a set of tags to the resource.
   * For each tag provided, it sends a request to assign the tag to the resource, then reloads the resource's tags.
   * @param tags An array of tags to assign to the resource.
   */
  public async assign(tags: TagEachResource[]) {
    if (tags && tags.length > 0) {
      const reqs = new Array<Promise<any>>()
      for (const tag of tags) {
        reqs.push(
          this.services.assignResource({
            tagId: tag.id,
            resourceId: this.resource.id
          })
        )
      }
      await Promise.all(reqs)
      await this.load()
    }
  }

  /**
   * Removes a set of tags from the resource.
   * For each tag provided, it sends a request to remove the tag from the resource, then reloads the resource's tags.
   * @param tags An array of tags to remove from the resource.
   */
  public async drop(tags: TagEachResource[]) {
    if (tags && tags.length > 0) {
      const reqs = new Array<Promise<any>>()
      for (const tag of tags) {
        reqs.push(
          this.services.dropResource({
            tagId: tag.id,
            resourceId: this.resource.id
          })
        )
      }
      await Promise.all(reqs)
      await this.load()
    }
  }
}
