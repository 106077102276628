import { Service } from '../../service'
import type {
  FilterGroupDto,
  NotificationUserSettings,
  NotificationUserSettingsResponse
} from './notifier-user-settings.interfaces'

export class NotificationUserService extends Service {
  public getCurrent() {
    return this.get<Array<NotificationUserSettingsResponse>>(`/api/notifier/settings`)
  }

  public create(data: NotificationUserSettingsResponse) {
    return this.post<Array<NotificationUserSettingsResponse>>(`/api/notifier/settings`, data)
  }
  public createBulk(data: NotificationUserSettings) {
    return this.post<Array<NotificationUserSettingsResponse>>(`/api/notifier/settings/bulk`, data)
  }

  public update(data: NotificationUserSettingsResponse) {
    return this.patch<Array<NotificationUserSettingsResponse>>(`/api/notifier/settings/bulk`, data)
  }

  public updateSingle(data: NotificationUserSettingsResponse) {
    return this.patch<Array<NotificationUserSettingsResponse>>(`/api/notifier/settings`, data)
  }

  public remove(ids: Array<string>) {
    return this.deleteData(`/api/notifier/settings`, { ids })
  }

  public loadGroups(): Promise<FilterGroupDto[]> {
    return this.get<FilterGroupDto[]>('/api/notifier/settings/groups')
  }

  public updateGroups(request: FilterGroupDto) {
    return this.patch<FilterGroupDto>('/api/notifier/settings/change-group', request)
  }
}
