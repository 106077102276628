export enum CameraDrawerMode {
  temp = 'temp',
  nvrdvr = 'nvrdvr',
  profile = 'profile'
}

export interface CameraDeleteProp {
  name: string
  id: string
}
