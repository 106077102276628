export default {
  steps: {
    workspace: 'Workspace',
    cameras: 'Cameras',
    pairing: 'Pairing',
    maps: 'Maps',
    pricing: 'Pricing',
    people: 'People',
    liveStream: 'Live Stream!'
  },
  workspace: {
    welcome: 'Welcome {data}! Tell us more about yourself.',
    tooltipWorkspaceName:
      "Picture this: Workspaces as personalized 'game zones'—each crafted with your chosen strategies, tools, and team. Whether it's a cozy home setup with family or leading as the Chief of Security at work, the excitement is unparalleled.",
    label: {
      name: 'Workspace Name',
      namePlaceHolder: 'Your location name',
      selectTimePlaceHolder: 'Select hours',
      industry: 'Industry',
      address: 'Address',
      yes: 'Yes',
      notReally: 'Not Really'
    },
    addressQuestion: 'Is this location designed for direct customer engagement? *',
    hoursOperations: 'Hours of Operations',
    hoursOperationsTitle: 'Proactive Alerts: Tailored to Your Business Hours',
    days: {
      sat: 'Saturday',
      sun: 'Sunday',
      mon: 'Monday',
      tue: 'Tuesday',
      wen: 'Wednesday',
      thu: 'Thursday',
      fri: 'Friday'
    },
    industry: {
      food: 'Food & Beverage',
      convenience: 'Convenience Store',
      clothing: 'Clothing & Apparel',
      furniture: 'Furniture & Home Appliance',
      sport: 'Sporting Goods',
      book: 'Book Stores, Musical Instruments',
      hotels: 'Hotels & Accommodations',
      travel: 'Travel',
      health: 'Health & Wellness, Personal Care',
      entertainment: 'Entertainment, Theatres, Venues',
      education: 'Education',
      finance: 'Finance ',
      construction: 'Construction ',
      cars: 'Cars',
      electronics: 'Electronics & Appliance, Technology ',
      home: 'Home Services',
      gift: 'Gift Shop',
      pet: 'Pet Supplies',
      other: 'Other'
    },
    successfullyChangeAddress: 'Your address has been successfully changed',
    copyToEveryDay: 'Copy to every day',
    NexStep: 'Next, Device Pairing',
    title: "The voyage to a safer tomorrow starts with you. Let's connect the dots!",
    inputLabel: 'Label your current whereabouts.',
    inputPlaceholder: 'Workspace Name',
    deviceTitle: 'Choose a device to pair:',
    deviceNotList: 'Device not listed? ',
    requestHere: 'Request Here',
    workspaceTooltip:
      "Imagine this - workspaces are your distinct personal 'game zones', each with unique plans, devices, and teammates chosen by you. Feel like building a cozy space at home and inviting your family to join? Or perhaps diving into a workspace at your job, taking on the role of the Chief of Security for Business? Thrilling, isn't it?\"",
    nextSetup: 'Next,  Camera Connect',
    bridge: 'via Bridge',
    nvr: 'via NVR/DVR',
    rtsp: 'via RTSP/HTTP',
    onvif: 'via Universal ONVIF ',
    arcadian: 'ArcadianAI'
  },
  pairing: {
    title: "Now, let's connect the dots. ",
    next: 'Skip to Pricing',
    selectBrand: 'Choose a brand to pair *'
  },
  inviteSuccess: 'Invites Sent!',
  alert: {
    title: 'Connection Interrupted',
    content: {
      header:
        'Your camera seems to have zoned out for a moment.' +
        "\n Let's reel it back in! Here are 4 immediate checks to bring it back online:",
      checks: {
        a: "1. Ensure the camera's power source is secure.",
        b: '\n 2. Double-check its connection to the router.',
        c: '\n 3. Turn off potential network barriers, like firewalls or VPNs.',
        d: "\n 4. Enter the camera's IP address in a web browser to verify its operational status."
      },
      footer: 'For deeper insights, consult our {link}.'
    },
    actions: {
      guide: 'troubleshoot guide',
      again: 'Again',
      support: 'Connect with an expert'
    }
  },
  tutorial: {
    description1: `Today, we embark on a transformative journey, unlocking the true potential of your existing physical security setup. Our guide will demystify essential tech concepts for you: firstly, the {staticIp}, the unique, unchanging digital home for your camera amidst the vast expanse of the internet. Next, we'll delve into {portForward}, a pivotal method letting external devices access your camera. We’ll guide you through the heart of your network, the {routerPanel}—a digital cockpit accessed via a web browser, where you hold the reins of all settings. Along the way, we’ll introduce you to {gettingPing}, a simple yet crucial test ensuring your camera’s seamless connectivity on the network. `,
    description2: `With the tools in hand, which include a reliable internet connection, a static IP address, and of course, your trusty security camera, by the culmination of this tutorial, you'll not only have your cameras streaming live on our platform but also fortified them with the best security practices in the realm. Ready to elevate your security game? Let’s dive right in!`,
    staticIp: 'Static IP Address',
    portForward: 'Port Forwarding',
    routerPanel: `Router's Admin Panel`,
    gettingPing: `Getting a Ping`
  },
  actions: {
    next: 'Setup finished! Next'
  }
}
