import type { Marker, UserData } from '@/lib/api'

export enum CameraDrivers {
  MediaServer = 'ms',
  Bridge = 'br'
}

export enum StreamMode {
  SD,
  HD
}

export enum RecordMode {
  always,
  HWMotion,
  pause
}

export type WeekDays<T> = {
  sunday: T
  monday: T
  tuesday: T
  wednesday: T
  thursday: T
  friday: T
  saturday: T
}

export interface Camera {
  id: string
  workspaceId: string
  remoteId: string
  remoteName: string
  groupId: string
  bridgeId?: string | null
  name: string
  description?: string
  timezone: string | null
  enable: boolean
  configured: boolean
  driver: CameraDrivers
  connectionInformation: CameraConnectionInformation
  streamInformation: CameraStreamInformation
  hardwareInformation: CameraHardwareInformation
  recordInformation: CameraRecordInformation
  scheduleInformation: CameraScheduleInformation
  createdAt: Date | string
  updatedAt: Date | string
  deletedAt: Date | string
  configAttempts: number
  avatar?: string
  healthStatus?: boolean
  maskData?: Array<Array<number>>
}

export interface CameraUser {
  cameraId: string
  users: UserData[]
}

export interface CameraMarker {
  cameraId: string
  marker: Marker
}

export interface CameraAdvance extends Camera {
  location?: string
  status: CameraStatusObject
  users: UserData[]
}

export interface CreateCameraData {
  id?: string
  name: string
  groupId: string
  config: CameraConnectionInformation
  bridgeId?: string | undefined
  timezone?: string
  userData?: string
  createdAt?: Date | string
  updatedAt?: Date | string
}

export interface UpdateCameraData {
  name?: string
  description?: string
  timezone?: string
  groupId?: string
  config?: CameraConnectionInformationData
  enable?: boolean
}

export interface CameraConnectionInformationData {
  url: string
  port: number
  securePort: number
  streamPort: number
  extraPorts: Array<number>
  username: string
  password: string
  channel: number
  useSSL: boolean
  manufacturerCode: number
}

export interface CameraConnectionInformation {
  url: string
  port: number
  securePort: number
  streamPort: number
  extraPorts: Array<number>
  username: string
  password: string
  channel: number
  useSSL: boolean
  manufacturerCode: number
}

export interface CameraHardwareInformation {
  features?: number
  model?: string
  manufacturer?: string
  hardwareID?: string
  serialNo?: string
  firmware?: string
  ip?: string
  MAC?: string
  comments?: string
}

export interface CameraStreamInformation {
  streamCount?: number
  highResIndex?: number
  lowResIndex?: number
  poorIndex?: number
  recordIndex?: number
  analyticIndex?: number
  mode: StreamMode
}

export interface CameraRecordInformation {
  availableArchiveAges: Array<number>
  availableModes: Array<RecordMode>
  archiveAge: number
  mode: RecordMode
  pausedAt?: Date | string
  raw?: unknown
}

export interface CameraScheduleInformation {
  remoteId: string
  enabled: boolean
}
export interface ScheduleWeekDetailData {
  friday: boolean[]
  monday: boolean[]
  saturday: boolean[]
  thursday: boolean[]
  sunday: boolean[]
  tuesday: boolean[]
  wednesday: boolean[]
}

export interface UpdateScheduleData {
  enable: boolean
  week: ScheduleWeekDetailData
}

export interface Schedule {
  enable: boolean
  week: WeekDays<Array<boolean>>
}

export interface CameraRecordModeData {
  recordMode: RecordMode
}

export type CameraReturnType = Omit<
  Camera,
  | 'connectionInformation'
  | 'scheduleInformation'
  | 'hardwareInformation'
  | 'remoteName'
  | 'remoteId'
>

export interface CameraArchiveMapData {
  archiveMap: Array<Array<number>>
}

export interface Manufactures {
  Manufacturer: number
  Name: string
  Description?: string
  Version?: string
  models?: string[]
}

export interface CrossLineConfig {
  enabled: boolean
  line: {
    start: {
      x: number
      y: number
    }
    end: {
      x: number
      y: number
    }
  }
}

export interface CameraStatusData {
  status: CameraStatusObject
  cameraId: string
  bridgeStatus?: CameraStatusObject
  configAttempts: number
}

export enum CameraHealthOverall {
  analyticStreamError = 'analyticStreamError',
  recordStreamError = 'recordStreamError',
  liveHighResStreamError = 'liveHighResStreamError',
  liveLowResStreamError = 'liveLowResStreamError',
  livePoorStreamError = 'livePoorStreamError',
  unconnected = 'unconnected',
  healthy = 'healthy',
  unhealthy = 'unhealthy'
}
export interface CameraStatusObject {
  enabled: boolean
  connecting: boolean
  connected: boolean
  recording: boolean
  analysing: boolean
  liveHighResStreamError: boolean
  liveLowResStreamError: boolean
  livePoorStreamError: boolean
  recordStreamError: boolean
  analyticStreamError: boolean
  program: boolean
  alarm: boolean
  recordFailed: boolean
  standBy: boolean
  streaming: boolean
  overall: CameraHealthOverall
  time?: number
}

export interface CameraStatusDirect {
  status:
    | {
        enabled: boolean
        connecting: boolean
        connected: boolean
        recording: boolean
        analysing: boolean
      }
    | boolean
  cameraId: string
}

export interface ThumbnailData {
  codecId: 0
  frame: ArrayBuffer
}

export interface VideoConfig {
  codec: string
  resolution: string
  frameRate: string
  bitrate: string
  detail: {
    resolution: {
      width: number
      height: number
    }
    frameRate: number
    bitrate: number
  }
}
export interface AvatarData {
  // file:File
}

export interface customManufactures {
  Manufacturer: number
  name: string
  models?: string[]
}

export enum CameraDownReason {
  payment,
  recordMode,
  status
}
export interface DowntimeMapEntity {
  id: string
  finished: boolean
  cameraId: string
  startedAt: string
  endedAt: string
  reason: CameraDownReason
  recordStarter: string | null
  recordStopper: string | null
}
