import { ResourceTypes } from '../permission/resource-type'
import type { Camera, Marker } from '@/lib/api'

export enum FindBridgeKeys {
  code,
  workspaceId
}
export enum BridgeStatus {
  inactivate,
  active,
  stall,
  disconnected
}
export enum BridgeType {
  hub = ResourceTypes.Bridge,
  camera = ResourceTypes.BridgeCamera
}
export interface BridgeActiveData {
  label?: string
  code: string
  timezone?: string
}
export interface UpdateBridgeData {
  label: string
  timezone: string
}
export interface BridgeData {
  id: string
  label: string
  code: string
  timezone: string
  status: BridgeStatus
  type: BridgeType
  model: string
  version: string
  locked: boolean
  workspaceId: string
  marker: Marker
  cameras?: Array<BridgeCamera | Camera>
}
export interface BridgeCamera {
  id: string
  remoteId: string
  bridgeId: string
  bridgeSystemId: string
}
export interface FindBridgeData {
  key: FindBridgeKeys
  value: string
}
export interface DiscoverCamerasData {
  manufacturer?: number
  from?: string
  to?: string
}
export interface DiscoveredCamera {
  model: string
  manufacturer: number
  realManufacturer?: string
  serialNumber: string
  firmware: string
  ip: string
  mac: string
  comments: string
}
export interface HttpSyncDeviceResponseData {
  serverSideCameras: Array<number>
  bridgeSideCameras: Array<DiscoveredCamera>
}
export interface CameraHandlerData {
  Manufacturer: number
  Name: string
  Description: string
  Version: string
}

export interface BridgeUpdateCheckData {
  current: string
  latest: string
  available: boolean
  compatible: boolean
  locked: boolean
}

export interface BridgeStatusData {
  status: BridgeStatus
  id: string
}
