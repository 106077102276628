import type { CameraService, CameraStatusData, CameraStatusObject } from '@/lib/api'
import { CameraCore } from '@/modules/Camera/CameraCore'
import type { CameraCoreComposite } from '@/modules/Camera/interface'
import { useServices } from '@/lib/services'
import type { Ref } from 'vue'
import { ref } from 'vue'
import { CameraStatusType } from '@/modules/Camera/types'
import { CameraHealthOverall } from '@/lib/api'
import { SyncAbleObject } from '@/modules/Camera/SyncAbleObject'
import { CameraStatusBackgroundService } from '@/modules/Camera/libs/status/CameraStatusBackgroundService'
import { PlayerRepository } from '@/player/lib/player/player-repository'

export class CameraStatus
  extends SyncAbleObject<CameraStatusObject>
  implements CameraCoreComposite
{
  public readonly id: string
  protected service: CameraService = useServices().camera
  protected static updater = new CameraStatusBackgroundService()
  public cameraStatus: Ref<CameraStatusType> = ref(CameraStatusType.connecting)
  private attemptReconnect: number = 0

  constructor(public readonly core: CameraCore) {
    super(CameraStatus.initialCameraStatusData())
    this.id = this.core.id
    this.update()
    CameraStatus.updater.register(this)
  }

  protected static initialCameraStatusData(): CameraStatusObject {
    return {
      enabled: false,
      connecting: false,
      connected: false,
      recording: false,
      analysing: false,
      liveHighResStreamError: false,
      liveLowResStreamError: false,
      livePoorStreamError: false,
      recordStreamError: false,
      analyticStreamError: false,
      program: false,
      alarm: false,
      recordFailed: false,
      standBy: false,
      streaming: false,
      overall: CameraHealthOverall.unhealthy
    }
  }

  private setCameraStatus(status: CameraStatusType): void {
    if (!this.core.locked) this.cameraStatus.value = status
    // this.onchangeStatus()
  }

  onchangeStatus() {
    if (
      this.cameraStatus.value === CameraStatusType.live ||
      this.cameraStatus.value === CameraStatusType.recording
    ) {
      this.core.thumbnail.loadThumbnail()
    }
  }

  async update(advance = false): Promise<void> {
    try {
      if (!this.core.locked) {
        this.setCameraStatus(CameraStatusType.connecting)
        const { status } = await this.fetch(advance)
        PlayerRepository.updateStatus(this.id, status) // to update player when other section of app updated
        this.setStatus(status)
      }
    } catch (error) {
      this.setCameraStatus(CameraStatusType.error)
    }
  }

  setStatus(status: CameraStatusObject) {
    Object.assign(this.data, status)
    this.sync()
    this.evaluate()
  }
  protected async fetch(advance: boolean): Promise<CameraStatusData> {
    if (advance || this.isCameraUpdated()) return this.service.statusCameraAdvance(this.id)
    else {
      return this.service.cameraStatus(this.id)
    }
  }
  protected evaluate(): void {
    if (!this.data) {
      this.attemptReconnect = 0
      this.setCameraStatus(CameraStatusType.troubleshoot)
      return
    }

    const { recording, connecting } = this.data
    const isHealthy = this.isCameraHealthy()
    if (connecting && this.attemptReconnect < 5) {
      this.setCameraStatus(CameraStatusType.connecting)
      this.handleConnectingStatus()
    } else if (isHealthy) {
      this.attemptReconnect = 0
      recording
        ? this.setCameraStatus(CameraStatusType.recording)
        : this.setCameraStatus(CameraStatusType.live)
    } else {
      this.attemptReconnect = 0

      recording
        ? this.setCameraStatus(CameraStatusType.connecting)
        : this.setCameraStatus(CameraStatusType.error)
    }
  }

  protected async handleConnectingStatus(): Promise<void> {
    this.attemptReconnect++
    await this.delay(2_000) // Wait for 5 seconds before attempting an update.
    await this.update(true)
  }

  getOverall() {
    return this.data.overall
  }

  isConnecting() {
    return this.data.connecting
  }

  isCameraHealthy(): boolean {
    return (
      Boolean(this.data) &&
      this.data.enabled &&
      !this.data.connecting &&
      (this.data.connected || this.data.standBy) &&
      !this.data.recordStreamError &&
      !this.data.liveHighResStreamError &&
      !this.data.liveLowResStreamError &&
      !this.data.livePoorStreamError
    )
  }

  protected isCameraUpdated(): boolean {
    const cameraUpdateTime = this.core.base.data.updatedAt.getTime()
    const cameraCreateTime = this.core.base.data.createdAt.getTime()
    const currentDate = new Date().getTime() - 15000 // Consider updated if within the last 15 seconds.
    return cameraUpdateTime >= currentDate || cameraCreateTime >= currentDate
  }

  destroy() {}

  unregisterFroUpdater() {}

  protected delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }
}
