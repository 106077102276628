import { defineStore, storeToRefs } from 'pinia'
import { useAuthenticationStore } from '@/stores/authentication/useAuthenticationStore'
import { useRouter } from 'vue-router'
import type { Router } from 'vue-router'
import { useTabStore } from '@/stores/tab/useTabStore'
import { useWorkspaceStore } from '@/modules/Workspace/store/useWorkspaceStore'
import { usePaymentStore } from '@/stores/payment/usePaymentStore'
import { useCustomSettingStore } from '@/stores/custom-setting/useCustomSettingStore'
import { useCameraStatusStore } from '@/stores/camera/useCameraStatusStore'

interface ApplicationStoreState {
  router: Router
  loading: Map<string | number, ReturnType<typeof setTimeout>>
  isReset: boolean
  fullscreenMode: boolean
  loadingData: Promise<any> | undefined
}

export const useApplicationStore = defineStore('Application', {
  state: (): ApplicationStoreState => ({
    router: useRouter(),
    isReset: false,
    fullscreenMode: false,
    loading: new Map(),
    loadingData: undefined
  }),

  getters: {
    totalLoading(): boolean {
      return this.loading.size > 0
    }
  },

  actions: {
    async loadApplicationData(): Promise<void> {
      if (this.loadingData === undefined) {
        useAuthenticationStore().resetAllStore(false)
        this.loadingData = useAuthenticationStore()
          .initializeTokens()
          .then(async () => {
            try {
              const { currentUser } = storeToRefs(useAuthenticationStore())
              await useAuthenticationStore().getCurrentUser()
              useAuthenticationStore().loadCurrentUserAvatar()
              if (currentUser.value?.workspaceId) {
                await useAuthenticationStore().reopenSessionSocket()
              }
              if (currentUser.value && !currentUser.value?.user?.phoneVerifiedAt) {
                await useAuthenticationStore().logout()
              } else {
                await this.loadRequiredData()
              }
              useCameraStatusStore().init() // safe to ignore returned Promise
            } catch (e) {
              await useAuthenticationStore().logout()
            } finally {
              this.loadingData = undefined
            }
          })
        await this.loadingData
      } else {
        await this.loadingData
      }
    },

    async loadRequiredData() {
      const { storage } = storeToRefs(useAuthenticationStore())
      if (
        storage.value.getItem('workspaceId') ||
        localStorage.getItem('workspaceId') ||
        sessionStorage.getItem('workspaceId')
      ) {
        try {
          await this.loadMainData()
          usePaymentStore().loadPaymentInformation()
          this.isReset = false
        } catch (e) {
          console.log(e)
          await useAuthenticationStore().logout()
        }
      } else {
        await this.router.push({ name: 'SelectWorkspace' })
      }
    },

    async loadMainData() {
      await Promise.all([
        useWorkspaceStore().getCurrentWorkspace(),
        useAuthenticationStore().loadPermissions(),
        useTabStore().loadTabs(),
        useCustomSettingStore().getUserWorkspaceListCustom(),
        useCustomSettingStore().getWorkspaceListCustom()
      ])
      await useTabStore().checkTabsPermission()
      await useTabStore().checkForMonitoringTabToBeExists()
    },

    startLoading(optionalKey?: string | number, time: number = 30_000) {
      const key = optionalKey || this.loading.size
      this.loading.set(
        key,
        setTimeout(() => this.stopLoading(key), time)
      )
    },

    enterFullscreen() {
      this.fullscreenMode = false
    },

    exitFullscreen() {
      this.fullscreenMode = false
    },

    stopLoading(key: string | number) {
      this.loading.delete(key)
    }
  }
})
