import {
  type CameraAdvance as CameraAdvanceObject,
  type CameraConnectionInformationData,
  type CameraReturnType,
  CameraService
} from '@/lib/api'
import { CameraConnectionInformation } from '@/modules/Camera/libs/connection-information/CameraConnectionInformation'
import { useServices } from '@/lib/services'

export class CameraConnectionInformationUpdateHelper {
  protected service: CameraService = useServices().camera
  protected stateLastUpdateAt = Date.now()
  public locked: boolean = false

  constructor(public readonly manager: CameraConnectionInformation) {}

  async update(data: CameraConnectionInformationData) {
    if (!this.locked) {
      const cameraObject = await this.updateCamera(data)
      this.updateDependencies(cameraObject)
      await this.manager.core.status.update(true)
      this.stateLastUpdateAt = Date.now()
    }
  }

  protected updateCamera(data: CameraConnectionInformationData): Promise<CameraReturnType> {
    return this.service.updateConnectionInfo(this.manager.id, data)
  }

  protected updateDependencies(cameraObject: CameraReturnType) {
    if (!this.locked) {
      Object.assign(
        this.manager.data,
        CameraConnectionInformation.parseCameraObject(cameraObject as CameraAdvanceObject)
      )
      this.manager.core.thumbnail.reset()
    }
  }

  block(state: boolean): void {
    this.locked = state
  }
}
