import { useServices } from '@/lib/services'
import { BridgeService, type BridgeStatusData } from '@/lib/api'
import { BridgeStatusManager } from '@/modules/Bridge/libs/status-manager/BridgeStatusManager'

export class BridgeStatusBackgroundService {
  protected readonly _service: BridgeService = useServices().bridge
  protected _interval: ReturnType<typeof setInterval>
  protected _instances: Set<BridgeStatusManager> = new Set()
  protected readonly INTERVAL_PERIOD = 30_000

  constructor() {
    this._interval = setInterval(this.update.bind(this), this.INTERVAL_PERIOD)
  }

  register(instance: BridgeStatusManager) {
    this._instances.add(instance)
  }

  unregister(instance: BridgeStatusManager) {
    this._instances.delete(instance)
  }

  protected async update() {
    if (this._service.getHeader('Authorization') && this._instances.size > 0) {
      const statusMap: Map<string, BridgeStatusData> = new Map()
      const status = await this._service.allStatus()
      status.forEach((s) => s && statusMap.set(s.id, s))
      for (const instance of this._instances) {
        const statusData = statusMap.get(instance.id)
        if (statusData) instance.setStatus(statusData.status)
      }
    }
  }

  restart() {
    if (this._interval) clearInterval(this._interval)
    this._interval = setInterval(this.update.bind(this), this.INTERVAL_PERIOD)
  }
}
