import { defineStore, storeToRefs } from 'pinia'
import { useServices } from '@/lib/services'
import type { CreateCustomerSettingResponse } from '@/lib/api'
import { CustomSettingsType } from '@/lib/api'
import { useWorkspaceStore } from '@/modules/Workspace/store/useWorkspaceStore'

interface customSettingState {
  userCustomList: Array<CreateCustomerSettingResponse>
  workspaceSettingList: Array<CreateCustomerSettingResponse>
  userWsCustomList: Array<CreateCustomerSettingResponse>
}

export const useCustomSettingStore = defineStore('custom-setting', {
  state: (): customSettingState => ({
    userCustomList: [],
    workspaceSettingList: [],
    userWsCustomList: []
  }),

  getters: {
    isOnboardUser(): boolean {
      return (
        !!this.workspaceSettingList.find((item) => item.key === 'onboardingUser') ||
        !!this.userWsCustomList.find((item) => item.key === 'onboardingUser')
      )
    },
    isAddFirstCamera(): boolean {
      return (
        !!this.workspaceSettingList.find((item) => item.key === 'addFirstCamera') ||
        !!this.userWsCustomList.find((item) => item.key === 'addFirstCamera')
      )
    }
  },

  actions: {
    async getUserListCustom() {
      this.userCustomList = await useServices().customSetting.list(CustomSettingsType.user)
    },

    async getWorkspaceListCustom() {
      this.workspaceSettingList = await useServices().customSetting.list(
        CustomSettingsType.workspace
      )
    },

    async getUserWorkspaceListCustom(force: boolean = false) {
      if (this.userWsCustomList.length === 0 || force) {
        this.userWsCustomList = await useServices().customSetting.list(
          CustomSettingsType.userWorkspace
        )
      }
    },

    async addWorkspaceSetting(data) {
      // await this.filterBeforeAddSetting(data.key)
      const response = await useServices().customSetting.create(data)
      this.userWsCustomList.push(response)
      return response
    },

    async filterBeforeAddSetting(key: string) {
      const existSetting = this.userWsCustomList.filter((setting) => setting.key === key)
      const promises = existSetting.map((setting) => this.removeWorkspaceSetting(setting.id))
      await Promise.all(promises)
    },

    async removeWorkspaceSetting(id: string) {
      await useServices().customSetting.remove(id)
      this.userWsCustomList = this.userWsCustomList.filter((item) => item.id !== id)
    },

    async createFirstAddCameraSetting() {
      const { currentWorkspace } = storeToRefs(useWorkspaceStore())
      if (!this.isAddFirstCamera) {
        const data = {
          type: CustomSettingsType.workspace,
          key: 'addFirstCamera',
          value: 'true',
          userId: currentWorkspace.value?.ownerId,
          workspaceId: currentWorkspace.value?.id
        }
        await this.addWorkspaceSetting(data)
        await this.getWorkspaceListCustom()
      }
    },

    async workspaceIsOnboarded() {
      const { currentWorkspace } = storeToRefs(useWorkspaceStore())
      const data = {
        type: CustomSettingsType.userWorkspace,
        key: 'onboardingUser',
        value: 'true',
        userId: currentWorkspace.value?.ownerId,
        workspaceId: currentWorkspace.value?.id
      }
      await this.addWorkspaceSetting(data)
      await this.getUserWorkspaceListCustom(true)
    }
  }
})
