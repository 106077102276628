import type { WorkSpaceCurrent } from '@/lib/api/services/workspace-manager/workspace/workspace.interfaces'
import { Languages } from '@/lib/api/services/workspace-manager/workspace/workspace.interfaces'
export interface UserWorkSpace extends WorkSpaceCurrent {
  status: string
}
export enum WorkspaceState {
  pending = 'pending',
  joined = 'joined',
  termsRequired = 'termsRequired',
  withdrawn = 'withdrawn',
  notFound = 'notFound'
}

export interface UserWorkSpaceData {
  id: string
  name: string
  description: string
  logo?: string
  locked: boolean
  lockForUser?: boolean
  ownerRemoteId: string
  language: Languages
  welcomeMessage: string
  activeTermsId: number
  ownerId: string
  theme: object
  createdAt: string
  status: WorkspaceState
  ownerName?: string
}
