import type { Camera as CameraObject, CameraStreamInformation } from '@/lib/api'
import { CameraCore } from '@/modules/Camera/CameraCore'
import { SyncAbleObject } from '@/modules/Camera/SyncAbleObject'
import type { CameraCoreComposite } from '@/modules/Camera/interface'

export class CameraStream
  extends SyncAbleObject<CameraStreamInformation>
  implements CameraCoreComposite
{
  public readonly id: string

  constructor(public readonly core: CameraCore, cameraObject: CameraObject) {
    super(CameraStream.parseCameraObject(cameraObject))
    this.id = this.core.id
  }

  protected static parseCameraObject(camera: CameraObject): CameraStreamInformation {
    return {
      streamCount: camera.streamInformation.streamCount,
      highResIndex: camera.streamInformation.highResIndex,
      lowResIndex: camera.streamInformation.lowResIndex,
      poorIndex: camera.streamInformation.poorIndex,
      recordIndex: camera.streamInformation.recordIndex,
      analyticIndex: camera.streamInformation.analyticIndex,
      mode: camera.streamInformation.mode
    }
  }

  public initialCameraData(cameraObject: CameraObject) {
    Object.assign(this.data, CameraStream.parseCameraObject(cameraObject))
  }
}
