import { defineStore } from 'pinia'
import { reactive } from 'vue'
import type { CameraStatusObject } from '@/lib/api'
import { useServices } from '@/lib/services'
export const useCameraStatusStore = defineStore('cameraStatusStore', () => {
  const CameraService = useServices().camera

  const cameras = reactive<Record<string, CameraStatusObject>>({})

  let initPromise: Promise<void> | undefined = undefined

  async function initInternal(): Promise<void> {
    const camerasStatus = await CameraService.statusAllCamera()
    for (const status of camerasStatus) {
      cameras[status.cameraId] = status.status
    }
  }
  async function init(): Promise<void> {
    initPromise = initInternal()
    initPromise.then(() => {
      initPromise = undefined
    })
    return initPromise
  }

  function reset(): void {
    for (const key in cameras) {
      delete cameras[key]
    }
  }

  function onCameraStatusUpdate(id: string, status: CameraStatusObject): void {
    delete status.time
    cameras[id] = status
  }

  async function getCameraStatusDirectly(id: string): Promise<CameraStatusObject> {
    const status = await CameraService.statusCameraAdvance(id)
    onCameraStatusUpdate(id, status.status)
    return status.status
  }

  async function getCameraStatus(id: string): Promise<CameraStatusObject> {
    if (cameras[id]) {
      return cameras[id]
    }
    if (initPromise) {
      await initPromise
      return getCameraStatus(id)
    }
    return getCameraStatusDirectly(id)
  }

  return {
    init,
    reset,
    onCameraStatusUpdate,
    getCameraStatus,
    cameras
  }
})
