import { messageType } from '@/components/general/messages/interface'
import type { CameraCore } from '@/modules/Camera/CameraCore'
import type { CameraTemp } from '@/modules/Camera/interface'
import { useEventBus } from '@/utils/event-bus/EventBus'
import { convertFromBlob } from '@/utils/helpers/general'
import { useCameraStore } from '../store/cameraStore'
import { translateTxt } from '@/modules/i18n'

function showToast(toast: unknown) {
  const eventBus = useEventBus()
  eventBus.emit('toast.add', toast)
}

export default class BridgeCameraWaiter {
  public readonly map: Map<
    string,
    {
      promise: Promise<void>
      timeoutId: ReturnType<typeof setTimeout> | number
      meta: {
        cameraTemp: CameraTemp
      }
    }
  > = new Map()

  async newCamera(camera: CameraCore, cameraTemp: CameraTemp) {
    if (cameraTemp.cameraData.bridgeId) {
      this.map.set(camera.id, {
        timeoutId: -1,
        promise: this.wait(camera, cameraTemp),
        meta: { cameraTemp }
      })
    } else {
      this.finalizeCreation(camera, cameraTemp)
    }
  }

  async wait(camera: CameraCore, cameraTemp: CameraTemp) {
    if (cameraTemp.cameraData.bridgeId) {
      const waitingObj = this.map.get(camera.id)
      if (waitingObj) {
        new Promise<boolean>((res) => {
          waitingObj.timeoutId = setTimeout(async () => {
            try {
              camera.getCameraData()
              await this.finalizeCreation(camera, cameraTemp)
              res(true)
            } catch (err) {
              useEventBus().emit('toast.add', {
                severity: messageType.error,
                details: translateTxt('camera.errorMessage.couldNotConnectCameraToBridge', {
                  name: cameraTemp.cameraData.name
                }),
                life: 5000
              })
              res(false)
            }
          }, 15_000)
        })
      }
    }
  }

  async onCreated(id: string) {
    const timeout = this.map.get(id)
    if (timeout) {
      const timeoutId = timeout.timeoutId
      const cameraTemp = timeout.meta.cameraTemp
      if (Number(timeoutId) > -1) clearTimeout(timeoutId)
      try {
        const camera = await useCameraStore().getCamera(id)
        let name = ''
        if (camera) {
          await this.finalizeCreation(camera, cameraTemp)
          this.map.delete(id)
          name = camera.base.data.name
        }

        showToast({
          severity: messageType.success,
          detail: translateTxt('camera.drawer.createdSuccessfully', { name }),
          life: 5000
        })

        return name
      } catch (e) {
        useEventBus().emit('toast.add', {
          severity: messageType.error,
          details: translateTxt('camera.errorMessage.couldNotConnectCameraToBridge', {
            name: cameraTemp.cameraData.name
          }),
          life: 5000
        })
      }
    }
  }

  async onFailed(id: string, name: string) {
    showToast({
      severity: messageType.error,
      detail: translateTxt('camera.errorMessages.cameraCreationFailed', { name: name ?? 'camera' }),
      life: 5000
    })
  }

  async finalizeCreation(camera: CameraCore, cameraTemp: CameraTemp) {
    camera.getCameraData()
    Promise.all([
      this._createTags(camera, cameraTemp),
      this._createAvatar(camera, cameraTemp),
      this._createLocation(camera, cameraTemp)
    ])
  }

  private async _createTags(camera: CameraCore, cameraTemp: CameraTemp): Promise<void> {
    if (cameraTemp.tags.length > 0) await camera.tagManager.assign(cameraTemp.tags)
  }

  private async _createAvatar(camera: CameraCore, cameraTemp: CameraTemp): Promise<void> {
    if (cameraTemp.avatar) {
      await camera.avatar.create(await convertFromBlob(cameraTemp.avatar))
    }
  }

  private async _createLocation(camera: CameraCore, cameraTemp: CameraTemp): Promise<void> {
    if (cameraTemp.location)
      await camera.base.locationManager.create(
        { longitude: cameraTemp.location.longitude, latitude: cameraTemp.location.latitude },
        cameraTemp.location.fullAddress
      )
  }
}

let bridgeCameraWaiter: BridgeCameraWaiter | undefined = undefined

export function useBridgeCameraWaiter(): BridgeCameraWaiter {
  if (!bridgeCameraWaiter) {
    bridgeCameraWaiter = new BridgeCameraWaiter()
  }
  return bridgeCameraWaiter
}
