import { computed, ref, onMounted } from 'vue'
import { useCameraStore } from '@/modules/Camera/store/cameraStore'
import { CameraCore } from '@/modules/Camera/CameraCore'
import { type CameraBaseObject } from '@/modules/Camera/interface'
import { useLayoutUpdateToaster } from '@/modules/Monitoring/helpers/layoutUpdateToaster'

let staticLoadCameraPromise: undefined | Promise<unknown> = undefined
export function useCameraStoreWrapper() {
  const cameraStore = useCameraStore()
  const loadingToaster = useLayoutUpdateToaster()

  const loading = ref(false)
  const cameras = computed<CameraCore[]>(() => {
    const result: Array<CameraCore> = []
    for (const id of cameraStore.cameraIdList) {
      const camera = cameraStore.getCameraFetched(id)
      if (camera && camera.base.data.enable && camera.base.data.configured) {
        result.push(camera as unknown as CameraCore)
      }
    }
    return result
  })
  const camerasBasic = computed<CameraBaseObject[]>(() => {
    return cameras.value
      .map((cc) => cc.base.data)
      .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
  })

  async function loadCameras() {
    if (staticLoadCameraPromise === undefined) {
      loading.value = true
      try {
        staticLoadCameraPromise = cameraStore
          .fetchCameras(false, true)
          .then(() => (staticLoadCameraPromise = undefined))
        await staticLoadCameraPromise
      } catch (e) {
        loadingToaster.showError()
        console.error(e)
        throw e
      } finally {
        loading.value = false
      }
    }
  }

  function getLoadingPromise() {
    return staticLoadCameraPromise
  }

  onMounted(loadCameras)

  return {
    loading,
    cameras,
    camerasBasic,
    loadCameras,
    getLoadingPromise
  }
}
