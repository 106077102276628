import type { BridgeActiveData, BridgeData, CameraService } from '@/lib/api'
import { useServices } from '@/lib/services'
import { BridgeService, BridgeStatus, ResourceTypes } from '@/lib/api'
import { TagManager } from '@/modules/tag/lib/TagManager'
import { MetadataManager } from '@/modules/metadata/lib/MetadataManager'
import { BridgeBaseInfo } from '@/modules/Bridge/libs/base-info/BridgeBaseInfo'
import { BridgeStatusManager } from '@/modules/Bridge/libs/status-manager/BridgeStatusManager'
import { computed, markRaw, reactive, type ComputedRef } from 'vue'

export class Bridge {
  protected readonly _service: BridgeService = useServices().bridge
  protected readonly _serviceCamera: CameraService = useServices().camera

  public readonly id!: string

  public readonly statusManager: BridgeStatusManager
  public readonly metadataManager: MetadataManager

  public fullLocked: ComputedRef = computed(() => {
    return (
      this.statusManager.status.value !== BridgeStatus.active ||
      this.base.versionManager.locked.value
    )
  })

  protected constructor(
    bridge: BridgeData,
    public readonly tagManager: TagManager,
    public readonly base: BridgeBaseInfo
  ) {
    this.id = bridge.id
    this.statusManager = reactive(markRaw(new BridgeStatusManager(this.id, bridge.status)))
    this.metadataManager = reactive(markRaw(new MetadataManager(this.id, ResourceTypes.Bridge)))
  }

  public static async init(bridge: BridgeActiveData | BridgeData | string): Promise<Bridge> {
    if (typeof bridge === 'string') {
      return await this.initById(bridge)
    } else if (typeof bridge === 'object' && 'id' in bridge) {
      return await this.initByBridge(bridge)
    } else {
      return await this.initByActivate(bridge)
    }
  }

  private static async initByActivate(activeData: BridgeActiveData) {
    const data = await useServices().bridge.active(activeData)
    return this.initByBridge(data)
  }

  private static async initById(id: string) {
    const data = await useServices().bridge.find(id)
    return this.initByBridge(data)
  }

  private static async initByBridge(bridge: BridgeData) {
    const tagManager = reactive(markRaw(await TagManager.setup(bridge.id, ResourceTypes.Bridge)))
    const baseInfo = reactive(markRaw(await BridgeBaseInfo.initiate(bridge.id, bridge)))
    return markRaw(new Bridge(bridge, tagManager, baseInfo))
  }

  async getBridgeData() {
    await Promise.all([this.base.fetch(), this.tagManager.load(), this.metadataManager.load()])
  }

  updateBridge(data: BridgeData) {
    this.base.setBridge(data)
  }

  public async fastDiscovery() {
    if (!this.fullLocked.value) {
      const { key } = await this._service.fastDiscovery(this.id)
      return await this.getDiscoveryResponse(key)
    }
    return []
  }

  public async fullDiscovery() {
    if (!this.fullLocked.value) {
      const { key } = await this._service.fullDiscovery(this.id)
      return await this.getDiscoveryResponse(key)
    }
  }

  protected async getDiscoveryResponse(key: string, maxAttempts = 5, interval = 5_000) {
    let attempts = 0
    while (attempts < maxAttempts) {
      try {
        await new Promise((resolve) => setTimeout(resolve, interval))
        const data = await this._service.discoveryResponse(key)
        if (data) {
          return data
        }
        attempts++
      } catch (error) {
        console.error('Error checking bridge status:', error)
        break
      }
    }
    throw new Error('Maximum attempts reached without active status')
  }

  public async heartBeat() {
    const { time } = await this._service.bridgeHeartbeat(this.id)
    return time
  }

  public async softRest() {
    if (!this.base.versionManager.locked.value) {
      await this._service.softReset(this.id)
      this.base.resetCamerasList()
    }
  }

  public async hardReset() {
    if (!this.base.versionManager.locked.value) {
      await this._service.hardReset(this.id)
    }
  }

  public async forceHardReset() {
    if (!this.base.versionManager.locked.value) {
      await this._service.forceReset(this.id)
    }
  }
}
