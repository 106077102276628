import type { ObjectEmitsOptions } from 'vue'
import { reactive, ref } from 'vue'
import {
  CameraStatus,
  Codec,
  FunctionMode,
  HeatMapPeriodType,
  InfoMode,
  PeriodType,
  SizeMode,
  StreamQuality,
  worker_url
} from '@/player/types'
import type {
  ActiveBookmark,
  ArchiveMap,
  Bookmark,
  CameraDetailedStatus,
  CameraInformation,
  CameraObject,
  DowntimeMapPLayerEntity,
  EmitFn,
  InfoMessage,
  PlayerAnalyticConfig,
  PlayerAnalyticSettingsConfig,
  PlayerAudioSetting,
  PlayerCrossLineConfig,
  PlayerHeatmapConfig,
  PlayerInitStateConfig,
  PlayerMaskConfig,
  PlayerParams,
  PlayerPlaybackConfig,
  PlayerSizeConfig,
  PlayerStreamQuality,
  PlayerWorkerCore,
  PlayerWorkerCoreConstructor,
  PlayerZoomConfig,
  PreviewConfig,
  SelectorConfig,
  snapshotStyleConfig
} from '@/player/interfaces'
import { BookmarkType } from '@/player/interfaces'
import { PlayerWatchers } from '@/player/lib/player/player-watchers'
import { PlayerHelpers } from '@/player/lib/player/player-helpers'
import { PlayerGetters } from '@/player/lib/player/player-getters'
import { PlayerApis } from '@/player/lib/player/player-apis'
import { Annotator } from '@/player/lib/annotator/annotator'
import { CaptureClient } from '@/player/lib/capture/capture-client'
import { TimelineCore } from '@/player/lib/playback-timeline/timeline-core'
import { HeatmapTimeline } from '@/player/lib/heatmap-timeline/heatmap-timeline'
import { SnapshotPainter } from '@/player/lib/snapshot/snapshot-painter'
import { DecoderImageDisplay } from '@/player/lib/decoder/decoder-image-display'
import { DecoderImageQueue } from '@/player/lib/decoder/decoder-image-queue'
import { PlayerUi } from '@/player/lib/player/player-ui'
import type { HeatMapDisplay } from '@/player/lib/heatmap/heatmap-display'
import { PlayerPreview } from '@/player/lib/preview/player-preview'
import * as Comlink from 'comlink'
import { TrackManager } from '@/player/lib/annotator/track-manager'
import { AudioPlayer } from '@/player/lib/audio/audio-player'
import { DigitalZoomController } from '@/player/lib/helpers/digital-zoom-controller'
import { MaskHelper } from '@/player/lib/mask/mask-helper'
import { ExportHelper } from '@/player/lib/helpers/export-helper'
import { PlayerRepository } from '@/player/lib/player/player-repository'
import { AnalyticEventTypes } from '@/lib/api'
import { AnalyticManager2 } from '@/player/lib/analytic/analytic-manager'
import { AnalyticHelper } from '@/player/lib/analytic/analytic-helper'
import { AnalyticService } from '@/player/lib/analytic/analytic-service'
import { useEventBus } from '@/utils/event-bus/EventBus'

export class PlayerCore {
  public cameraId: string
  public initParams?: PlayerInitStateConfig
  public information: CameraInformation
  public rootEl!: HTMLElement
  public fullscreenMode = ref(false)
  public status = ref(CameraStatus.unknown)
  public detailedStatus = ref<CameraDetailedStatus>()
  public archiveMap = ref<ArchiveMap>([])
  public downMap = ref<DowntimeMapPLayerEntity[]>([])
  public bookmarks = ref<Bookmark[]>([])
  public isNewBookmark = ref(false)
  public editBookmarkId = ref('')
  public closed = false
  public noLayout = ref(false)
  public selectorData = reactive<SelectorConfig>({
    isActive: false,
    start: 0,
    end: 0,
    color: '#FFFFFF',
    startX: 0,
    startY: 0,
    startPath: undefined,
    endPath: undefined,
    description: ''
  })
  public activeBookmark = reactive<ActiveBookmark>({
    enabled: false,
    x: 0,
    y: 0,
    bookmark: undefined,
    type: BookmarkType.userMade
  })
  public previewConfig = reactive<PreviewConfig>({
    enabled: false,
    x: 0,
    y: 0,
    date: 0
  })
  public isExportMode = ref(false)
  public snapshotStyle = reactive<snapshotStyleConfig>({
    color: '#6c6c6c',
    size: 10,
    eraser: false
  })
  public mode = ref(FunctionMode.liveMode)
  public supportPTZ = ref(false)
  public size = reactive<PlayerSizeConfig>({
    type: 'small',
    mode: SizeMode.scale,
    desiredHeight: undefined,
    desiredWidth: undefined,
    renderHeight: 0,
    renderWidth: 0,
    wrapperWidth: 0,
    wrapperHeight: 0,
    layoutIndex: -1
  })
  public playback = reactive<PlayerPlaybackConfig>({
    lastFrameDate: Date.now(),
    speed: 1,
    timelineSize: PeriodType.singleMinute,
    isLive: true,
    streamIndex: 0
  })
  public streams = reactive<PlayerStreamQuality>({
    quality: StreamQuality.medium,
    isAuto: true
  })
  public infoConfig = reactive<InfoMessage>({
    mode: InfoMode.expand,
    message: '',
    at: 0,
    show: false
  })
  public heatmapConfig = reactive<PlayerHeatmapConfig>({
    opacity: 0.4,
    timelineSize: HeatMapPeriodType.short,
    activeImage: undefined,
    displayFlipper: true
  })
  public zoomConfig = reactive<PlayerZoomConfig>({
    enabled: false,
    level: 1,
    origin: {
      x: 0,
      y: 0
    }
  })
  public audioSetting = reactive<PlayerAudioSetting>({
    enabled: false,
    lastCodec: Codec.unknown,
    volume: 0
  })
  public maskConfig = reactive<PlayerMaskConfig>({
    enabled: false,
    editMode: false
  })
  public analyticConfig = reactive<PlayerAnalyticConfig>({
    enabled: false,
    activeEventType: AnalyticEventTypes.None,
    activeIndex: '',
    isMenuOpen: false
  })
  public analyticSettings = reactive<PlayerAnalyticSettingsConfig>({
    type: AnalyticEventTypes.None,
    new: true,
    hasPendingUpdate: false,
    ref: undefined
  })
  public crossLineConfig = reactive<PlayerCrossLineConfig>({
    enabled: false,
    item: {
      enabled: false,
      line: {
        start: {
          x: 0,
          y: 0
        },
        end: {
          x: 0,
          y: 0
        }
      }
    }
  })

  public getters: PlayerGetters
  public helpers: PlayerHelpers
  public watchers: PlayerWatchers
  public apis: PlayerApis
  public ui: PlayerUi
  public annotator!: Annotator
  public trackManager: TrackManager
  public heatmapTimeline!: HeatmapTimeline
  public capture!: CaptureClient
  public decoderQueue!: DecoderImageQueue
  public display!: DecoderImageDisplay
  public heatMapDisplay!: HeatMapDisplay
  public timeline!: TimelineCore
  public snapshotPainter!: SnapshotPainter
  public preview!: PlayerPreview
  public emitFunction!: EmitFn<ObjectEmitsOptions, 'profileRequested' | 'sizeChanged' | 'drag'>
  public workerHandler!: Worker
  public worker!: Comlink.Remote<PlayerWorkerCore>
  public audioPlayer!: AudioPlayer
  public digitalZoomController: DigitalZoomController
  public maskHelper: MaskHelper
  // public analyticManager: AnalyticManager
  public exportHelper: ExportHelper
  public analyticManager2: AnalyticManager2
  public analyticHelper: AnalyticHelper
  public analyticService: AnalyticService

  showInfo(message: string, mode = InfoMode.message) {
    this.infoConfig.message = message
    this.infoConfig.mode = mode
    this.infoConfig.at = Date.now()
    if (mode === InfoMode.message || mode === InfoMode.expand) {
      useEventBus().emit('toast.add', {
        severity: 'info',
        summary: message,
        life: 5000
      })
    }
  }

  destroy() {
    console.log('destroy called on', this.id)
    this.closed = true
    if (this.capture) {
      this.capture.close()
    }
    if (this.decoderQueue) {
      this.decoderQueue.clean()
    }
    if (this.display) {
      this.display.destroy()
    }
    if (this.preview) {
      this.preview.destroy()
    }
    if (this.workerHandler) {
      this.workerHandler.terminate()
    }
    if (this.timeline) {
      this.timeline.disable()
    }
    if (this.audioPlayer) {
      this.audioPlayer.close()
    }
    this.helpers.keyboardShortcuts.destroy()
  }

  async reassign(camera: CameraObject) {
    this.cameraId = camera.id.toString()
    Object.assign(this.information, camera)
    await this.helpers.initializer.initializeRequiredAPIs()
    await this.helpers.initializer.initializeCapture()
  }

  constructor(camera: CameraObject, public readonly id: string) {
    console.log('new player', this.id)
    this.cameraId = camera.id.toString()
    this.information = reactive({ ...camera })
    this.getters = new PlayerGetters(this)
    this.helpers = new PlayerHelpers(this)
    // this.analyticManager = new AnalyticManager(this)
    this.analyticManager2 = new AnalyticManager2(this)
    this.analyticHelper = new AnalyticHelper(this)
    this.analyticService = new AnalyticService(this)
    this.apis = new PlayerApis(this)
    this.ui = new PlayerUi()
    this.decoderQueue = new DecoderImageQueue(this)
    this.display = new DecoderImageDisplay(this)
    this.trackManager = new TrackManager(this)
    this.timeline = new TimelineCore(this)
    this.audioPlayer = new AudioPlayer(this)
    this.digitalZoomController = new DigitalZoomController(this)
    this.maskHelper = new MaskHelper(this)
    this.exportHelper = new ExportHelper(this)
    this.watchers = new PlayerWatchers(this)
  }

  async initialize(params: PlayerParams) {
    this.initParams = params.initState
    this.workerHandler = new Worker(worker_url)
    const proxy = Comlink.wrap<PlayerWorkerCoreConstructor>(this.workerHandler)
    this.worker = await new proxy()
    this.noLayout.value = params.noLayout === true
    await this.helpers.initializer.initialize(params)
  }

  setActivePlayer() {
    PlayerRepository.activePlayer = this
  }
}
