import type { Camera as CameraObject, CameraConnectionInformationData } from '@/lib/api'
import { CameraService } from '@/lib/api'
import { CameraCore } from '@/modules/Camera/CameraCore'
import type {
  CameraConnectionInformationObject,
  CameraCoreComposite
} from '@/modules/Camera/interface'
import { SyncAbleObject } from '@/modules/Camera/SyncAbleObject'
import { CameraConnectionInformationUpdateHelper } from '@/modules/Camera/libs/connection-information/CameraConnectionInformationUpdateHelper'
import { useServices } from '@/lib/services'
import { CameraStatusType } from '@/modules/Camera/types'
import sortObjectByKeys from '@/utils/sortObject/byKeys'

export class CameraConnectionInformation
  extends SyncAbleObject<CameraConnectionInformationObject>
  implements CameraCoreComposite
{
  public readonly id: string
  public readonly updater: CameraConnectionInformationUpdateHelper
  protected service: CameraService = useServices().camera

  constructor(public readonly core: CameraCore, camera: CameraObject) {
    super(CameraConnectionInformation.parseCameraObject(camera))
    this.id = camera.id
    this.updater = new CameraConnectionInformationUpdateHelper(this)
  }

  public static parseCameraObject(camera: CameraObject): CameraConnectionInformationObject {
    return {
      url: camera.connectionInformation.url,
      port: camera.connectionInformation.port || CameraConnectionInformation.getDefaultPort(),
      securePort: camera.connectionInformation.securePort,
      streamPort:
        camera.connectionInformation.streamPort || CameraConnectionInformation.getDefaultPort(),
      extraPorts: camera.connectionInformation.extraPorts,
      username: camera.connectionInformation.username || '',
      password: camera.connectionInformation.password || '',
      channel: camera.connectionInformation.channel,
      useSSL: camera.connectionInformation.useSSL,
      manufacturerCode: camera.connectionInformation.manufacturerCode,
      configured: camera.configured,
      configAttempts: camera.configAttempts
    }
  }

  // helpers methods
  public static getDefaultPort() {
    return 80
  }

  public static getDefaultSecurePort() {
    return 443
  }

  public static getDefaultStreamPort() {
    return 554
  }

  public static validateUrl(value: string): boolean {
    const ipv4Regex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/
    let invalidURL: boolean = false
    try {
      new URL(value)
    } catch {
      invalidURL = true
    }
    return !value || !invalidURL || ipv4Regex.test(value)
  }

  public async update(
    data: CameraConnectionInformationData
  ): Promise<CameraConnectionInformationData | void> {
    if (!this.core.locked) {
      const newData = JSON.stringify(sortObjectByKeys(data))
      const oldData = JSON.stringify(sortObjectByKeys(this.data))
      if (oldData !== newData) {
        return this.updater.update(data)
      }
    }
  }
  public initialCameraData(camera: CameraObject) {
    Object.assign(this.data, CameraConnectionInformation.parseCameraObject(camera))
  }

  setStatus(status: CameraStatusType) {
    if (!this.core.locked) this.core.status.cameraStatus.value = status
  }

  public async retry() {
    this.setStatus(CameraStatusType.connecting)
    if (!this.core.locked) {
      await this.service.retry(this.id)
      await this.core.status.update(true)
    }
  }
}
