export enum messageType {
  success = 'success',
  error = 'error',
  warning = 'warn',
  info = 'info',
  secondary = 'secondary',
  contrast = 'contrast'
}

export enum messageStyle {
  base = 'base',
  outline = 'outline',
  primary = 'primary'
}
