import { ref, onMounted, computed } from 'vue'
import { useLayoutUpdateToaster } from '@/modules/Monitoring/helpers/layoutUpdateToaster'
import { useBridgeStore } from '@/modules/Bridge/store'
import type { Bridge } from '@/modules/Bridge/Bridge'
import { useCameraStore } from '@/modules/Camera/store/cameraStore'
import type { CameraCore } from '@/modules/Camera/CameraCore'
import { groupBy } from 'lodash'

let staticLoadBridgePromise: undefined | Promise<unknown> = undefined
export function useBridgeStoreWrapper() {
  const bridgeStore = useBridgeStore()
  const cameraStore = useCameraStore()
  const loadingToaster = useLayoutUpdateToaster()

  const loading = ref(false)
  const bridges = computed<Bridge[]>(() => {
    const result: Array<Bridge> = []
    for (const id of bridgeStore.bridgesIdList) {
      const bridge = bridgeStore.getFetchedBridge(id)
      if (bridge) {
        result.push(bridge as unknown as Bridge)
      }
    }
    return result
  })

  const bridgeCameras = computed(() => {
    const camerasList: Array<CameraCore> = []
    for (const cameraId of cameraStore.cameraIdList) {
      const camera = cameraStore.getCameraFetched(cameraId)
      if (camera && camera.bridge.bridgeId) {
        camerasList.push(camera)
      }
    }
    const groupedCameras = groupBy(camerasList, 'bridge.bridgeId')
    return groupedCameras
  })

  onMounted(loadBridges)

  async function loadBridges() {
    if (bridgeStore.bridges.length === 0 && staticLoadBridgePromise === undefined) {
      loading.value = true
      try {
        staticLoadBridgePromise = bridgeStore
          .fetchBridges()
          .then(() => (staticLoadBridgePromise = undefined))
        await staticLoadBridgePromise
      } catch (e) {
        loadingToaster.showError()
        console.error(e)
        throw e
      } finally {
        loading.value = false
      }
    }
  }

  function getLoadingPromise() {
    return staticLoadBridgePromise
  }

  return {
    loading,
    bridges,
    bridgeCameras,
    loadBridges,
    getLoadingPromise
  }
}
