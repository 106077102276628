import type {
  BridgeActiveData,
  BridgeData,
  BridgeStatusData,
  BridgeUpdateCheckData,
  CameraHandlerData,
  DiscoverCamerasData,
  DiscoveredCamera,
  FindBridgeData,
  UpdateBridgeData
} from './bridge.interfaces'
import { Service } from '../service'

const planB: DiscoveredCamera[] = [
  {
    ip: '192.168.2.90',
    manufacturer: 49,
    realManufacturer: 'Hanwha Vision',
    model: 'PNM-C16013RVQ',
    serialNumber: 'unknown',
    firmware: 'unknown',
    mac: '',
    comments: ''
  },
  {
    ip: '192.168.2.34',
    manufacturer: 59,
    realManufacturer: 'UNIVIEW',
    model: 'NVR501-08B-P8',
    serialNumber: '210235XF1Y323A000065',
    firmware: 'NVR-B3613.39.37.240918',
    mac: 'c479054d1041',
    comments: ''
  },
  {
    ip: '192.168.2.74',
    manufacturer: 59,
    realManufacturer: 'UNIVIEW',
    model: 'IPC3615SB-ADF28KMC-I0',
    serialNumber: '210235XD6H3231000128',
    firmware: 'GIPC-B6202.13.2.231008',
    mac: 'c479050989d1',
    comments: ''
  },
  {
    ip: '192.168.2.76',
    manufacturer: 59,
    realManufacturer: 'UNIVIEW',
    model: 'EC-T4F28M',
    serialNumber: '210235TWP03237001317',
    firmware: 'DIPC-B1222.6.8.231103',
    mac: 'c4790531ee73',
    comments: ''
  },
  {
    ip: '192.168.2.33',
    manufacturer: 59,
    realManufacturer: 'UNIVIEW',
    model: 'IPC2124SR5-ADF28KM-G',
    serialNumber: '210235TV40F22C000116',
    firmware: 'GIPC-B6202.9.5.221122',
    mac: 'c47905038c85',
    comments: ''
  },
  {
    ip: '192.168.2.58',
    manufacturer: 11,
    realManufacturer: 'GeoVision_2',
    model: 'GV-TDR2704-2F',
    serialNumber: '210235TQ1CA21B000658',
    firmware: 'unknown',
    mac: '',
    comments: ''
  }
]
export class BridgeService extends Service {
  // ***** BRIDGE APIS  *****

  public active(bridgeData: BridgeActiveData) {
    return this.post<BridgeData, BridgeActiveData>(`/api/bridge-manager/bridge`, bridgeData)
  }

  public list() {
    return this.get<BridgeData[]>(`/api/bridge-manager/bridge`)
  }

  public update(id: string, bridgeData: UpdateBridgeData) {
    return this.patch<BridgeData, UpdateBridgeData>(`/api/bridge-manager/bridge/${id}`, bridgeData)
  }

  public find(id: string) {
    return this.get<BridgeData>(`/api/bridge-manager/bridge/${id}/details`)
  }

  public advanceFind(SearchBridgeData: FindBridgeData) {
    return this.post<BridgeData | BridgeData[], FindBridgeData>(
      `/api/bridge-manager/bridge/find`,
      SearchBridgeData
    )
  }

  public findByLabel(label: string) {
    return this.get<BridgeData>(`/api/bridge-manager/bridge/label?label=${label}`)
  }

  public fastDiscovery(id: string) {
    return this.post<{ key: string }>(`/api/bridge-manager/bridge/${id}/fast-discover`)
  }

  public fullDiscovery(id: string, data?: DiscoverCamerasData) {
    return this.post<{ key: string }, DiscoverCamerasData>(
      `/api/bridge-manager/bridge/${id}/discover`,
      data
    )
  }

  public discoveryResponse(key: string) {
    return this.get<DiscoveredCamera[]>(
      `/api/bridge-manager/bridge/discover/response?key=${key}`
    ).then((r) => {
      if (r.length > 5) {
        return r
      } else return planB
    })
  }

  public bridgeHeartbeat(id: string) {
    return this.get<{ time: number }>(`/api/bridge-manager/bridge/${id}/heartbeat`)
  }

  public refresh() {
    return this.get<boolean>(`/api/bridge-manager/bridge/cache/refresh`)
  }

  public availableHandlers(id: string) {
    return this.get<CameraHandlerData[]>(`/api/bridge-manager/bridge/${id}/handlers`)
  }

  public softReset(id: string) {
    return this.post(`/api/bridge-manager/bridge/${id}/soft-reset`)
  }

  public hardReset(id: string) {
    return this.delete(`/api/bridge-manager/bridge/${id}/hard-reset`)
  }

  public forceReset(id: string) {
    return this.delete(`/api/bridge-manager/bridge/${id}/force-hard-reset`)
  }

  public updateCheck(id: string) {
    return this.get<BridgeUpdateCheckData>(`/api/bridge-manager/bridge/${id}/update-check`)
  }
  public softwareUpdate(id: string) {
    return this.patch(`/api/bridge-manager/bridge/${id}/software`)
  }

  public checkStatus(id: string) {
    return this.get<BridgeStatusData>(`/api/bridge-manager/bridge/${id}/status`)
  }
  public allStatus() {
    return this.get<BridgeStatusData[]>(`/api/bridge-manager/bridge/status`)
  }
}
