import { BridgeType, type BridgeCamera, type BridgeData, type Camera } from '@/lib/api'
import { BridgeService, ResourceTypes, type UpdateBridgeData } from '@/lib/api'
import { useServices } from '@/lib/services'
import { markRaw, reactive, ref, type Ref } from 'vue'
import { LocationManager } from '@/modules/location/lib/LocationManager'
import { BridgeVersionManager } from '../version-manager/BridgeVersionManager'
import { useCameraStore } from '@/modules/Camera/store/cameraStore'
import { useOnboardingStore } from '@/modules/Onboarding/store/useOnboardingStore'
import { uniqueId } from 'lodash'

export class BridgeBaseInfo {
  protected readonly service: BridgeService = useServices().bridge
  public readonly _reandomId: string = uniqueId()
  public versionManager: BridgeVersionManager
  public locationManager: LocationManager
  public id: string = ''
  public label: Ref<string> = ref('')
  public code: string = ''
  public timezone: string = ''
  public type: BridgeType = BridgeType.hub
  public model: string = ''
  public workspaceId: string = ''
  public cameras: Array<BridgeCamera | Camera> = reactive([])

  constructor(id: string, bridge: BridgeData) {
    this.setBridge(bridge)
    this.versionManager = markRaw(new BridgeVersionManager(id, { version: bridge.version }, this))
    this.locationManager = markRaw(new LocationManager(id, ResourceTypes.Bridge))
  }

  static async initiate(id: string, bridge: BridgeData): Promise<BridgeBaseInfo> {
    return new BridgeBaseInfo(id, bridge)
  }

  static blockCameras(id: string, locked: boolean) {
    useCameraStore().blockCameras(id, locked)
    useOnboardingStore().blockTempCameras(id, locked)
  }

  public async update(data: UpdateBridgeData) {
    if (!this.versionManager.locked.value) {
      const bridgeData = await this.service.update(this.id, data)
      this.setBridge(bridgeData)
    }
  }

  public async fetch() {
    const bridgeData = await this.service.find(this.id)
    this.setBridge(bridgeData)
  }

  public setBridge(bridge: BridgeData) {
    this.id = bridge.id
    this.label.value = bridge.label
    this.code = bridge.code
    this.timezone = bridge.timezone
    this.type = bridge.type
    this.model = bridge.model
    this.workspaceId = bridge.workspaceId
    if (bridge.marker) this.locationManager.setData(bridge.marker)
    if (bridge.cameras) this.cameras.push(...[])
    this.fetchCamerasInStore()
  }

  async fetchCamerasInStore() {
    const cameraFetchPromises = this.cameras.map(async (c) => {
      try {
        return useCameraStore().fetchCamera(c.id)
      } catch (error) {
        console.error(`Error fetching camera with ID ${c.id}:`, error)
        return null
      }
    })

    try {
      await Promise.all(cameraFetchPromises.filter((c) => c !== null))
    } catch (error) {
      console.error(`Error fetching camera with ID: `, error)
    }
  }

  dropCamera(id: string) {
    const index = this.cameras.findIndex((c) => c.id === id)
    this.cameras.splice(index, 1)
  }

  public resetCamerasList() {
    this.cameras.length = 0
  }
}
