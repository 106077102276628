import type { SessionMode, UserData } from '../authentication/authentication.interfaces'

export enum ChallengeType {
  password = 'password',
  otp = 'otp',
  tOtp = 'tOtp'
}

export enum SessionState {
  password = 'password',
  phoneOtp = 'otp',
  tOtp = 'tOtp',
  register = 'register',
  success = 'success'
}

export type ChallengeState = ChallengeType | SessionState

export interface ChallengeInitData {
  username: string
  mode: SessionMode
}

export type ResponseChallengeInit<Type> = {
  accessToken: string
  refreshToken?: string
  status: Type
  createdAt?: string
}
export interface ChallengeVerifyData {
  token: string
  value: string
}
export interface ChallengeRegisterData {
  token: string
}
export interface ChallengeRegisterResponse {
  accessToken: string
  refreshToken: string | null
  status: SessionState
  user: UserData
}
export interface ChallengeRetryData {
  token: string
}
export interface ChallengeOrderData {
  challengeId: string
  newGivenStep: number
}
export interface ChallengeOrderEntity {
  id: string
  userId: string
  step: number
  isFinal: boolean
  type: ChallengeType
  updatedAt: Date
  createdAt: Date
}
export interface UpdatePhoneData {
  phone: string
}
export interface ResponseUpdatePhone {
  otpId: string
}
export interface ChallengeVerifyUpdatePhoneData {
  value: string
  otpId: string
}

export interface eachStatus {
  status: string
  count: number
}
