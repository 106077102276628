import type { CameraTemp } from '@/modules/Camera/interface'
import type { CameraStatusType } from '@/modules/Camera/types'
import type { WorkSpaceUser, CreateCameraData, TagEachResource } from '@/lib/api'

export interface OnboardingStep {
  title: string
  key: OnboardingStepEnum
  order: number
  status: boolean
  percent?: number
}

export enum OnboardingStepEnum {
  CAMERA_CONNECT = 'cameraConnect',
  WORKSPACE = 'workspace',
  CAMERAS = 'cameras',
  PAIRING = 'pairing',
  MAPS = 'maps',
  PRICING = 'pricing',
  PEOPLE = 'people',
  LIVE_STREAM = 'liveStream'
}

export enum OnboardingStepLists {
  'workspace' = 'workspace',
  'cameras' = 'cameras',
  'maps' = 'maps',
  'pricing' = 'pricing',
  'people' = 'people',
  'liveStream' = 'liveStream'
}

export interface OnboardingWorkspace {
  name: string
  avatar: any
  description: string
  process: number
}

export enum CameraStep {
  ip = 1,
  webGate,
  connection
}

export interface OnboardingCamera {
  currentStep: CameraStep
  cameraList: CameraTemp[]
  process: number[]
  staticIp?: string
  verifyConfigureIp?: boolean
  selections: CameraTemp[]
}
export interface OnboardingPayment {
  fullName: string
  zipCode: string
}

export interface onboardingData {
  camera: OnboardingCamera
  payment: OnboardingPayment
  cameraUsers: WorkSpaceUser[]
  isBridgeSetupFinished: boolean
  bridgeId: string
}

export interface OnboardingState {
  currentStep: OnboardingStepEnum
  steps: OnboardingStep[]
  nextStep: OnboardingStepEnum
  data: onboardingData
}

export type VideoOnboarding = {
  subHeader: string
  header: string
  description: string
  tag: string[]
  src: string
}

export type SubmitCreateCamera = {
  cameraData: CreateCameraData
  tags: TagEachResource[]
  avatar?: any
  status: CameraStatusType
  isRecording: boolean
  location?: {
    longitude: number
    latitude: number
    fullAddress: string
    markerId: string
  }
}

export enum TypeInvite {
  phone = 1,
  email
}
export interface MenuItemPosition {
  label: string
  action: string
  id?: string
}
