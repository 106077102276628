import { useServices } from '@/lib/services'
import {
  type MapEntity,
  MapManagerService,
  type MapResource,
  type Marker,
  ResourceTypes
} from '@/lib/api'
import type { Location } from '@/modules/Camera/interface'
import { reactive } from 'vue'

export class LocationManager {
  public location!: Location
  private readonly mapManager: MapManagerService = useServices().mapManager
  protected defaultMap!: MapEntity
  protected resource!: MapResource

  constructor(
    public readonly remoteId: string,
    public readonly type: ResourceTypes,
    marker: Marker | null = null
  ) {
    const data = {
      longitude: marker ? marker.position.longitude : 0,
      latitude: marker ? marker.position.latitude : 0,
      fullAddress: marker ? marker.config.fullAddress : '',
      markerId: marker ? marker.id : ''
    }
    this.location = reactive<Location>(data) as Location
  }

  public async load() {
    this.defaultMap = await this.mapManager.map.findAll().then((maps) => maps[0])
    if (this.defaultMap.markers && this.defaultMap.markers.length > 0)
      this.setData(this.defaultMap.markers[0])
    this.resource = await this.mapManager.resource.find({
      remoteId: this.remoteId,
      type: this.type
    })
  }

  public async create(
    locationPosition: {
      longitude: number | string
      latitude: number | string
    },
    fullAddress: string
  ): Promise<void> {
    if (!this.defaultMap) await this.load()
    const marker = await this.mapManager.marker.create({
      mapId: String(this.defaultMap.id),
      position: locationPosition,
      config: { fullAddress }
    })
    await this.mapManager.marker.attachLayer(String(marker.id), {
      markerId: String(marker.id),
      resourceId: String(this.resource.id)
    })
    this.setData(marker) // Update the internal location data with the new marker.
  }

  public async update(
    locationPosition: {
      longitude: number | string
      latitude: number | string
    },
    fullAddress: string
  ): Promise<void> {
    const marker = await this.mapManager.marker.update(this.location.markerId as string, {
      position: locationPosition,
      config: { fullAddress }
    })
    this.setData(marker) // Update the internal location data with the updated marker.
  }

  public async remove(): Promise<void> {
    await this.mapManager.marker.delete(this.location.markerId as string)
    this.reset() // Reset the internal location data.
  }

  public setData(marker: Marker): void {
    const data = {
      longitude: marker ? marker.position.longitude : 0,
      latitude: marker ? marker.position.latitude : 0,
      fullAddress: marker ? marker.config.fullAddress : '',
      markerId: marker ? marker.id : ''
    }
    Object.assign(this.location, data)
  }

  private reset(): void {
    const data = {
      longitude: 0,
      latitude: 0,
      fullAddress: '',
      markerId: ''
    }
    Object.assign(this.location, data)
  }
}
