import { reactive } from 'vue'
import { uniqueId } from 'lodash'
import type { AuthoritiesFinderOptions, mapValue } from '@/modules/Permissions'

export class PermissionsOrganizer {
  permissionsMap = reactive<Map<string, mapValue>>(new Map())

  constructor() {}

  add(actions: AuthoritiesFinderOptions[] | AuthoritiesFinderOptions, hasPermissions: boolean) {
    const uid = uniqueId()
    this.permissionsMap.set(uid, { actions, hasPermissions })
    return { actions, hasPermissions: this.get(uid), uid }
  }

  get(uid: string) {
    return this.permissionsMap.get(uid)?.hasPermissions
  }
}
