export default {
  bridge: {
    connectCamera: 'Connect Cameras',
    arcadianCamera: {
      title: "Fantastique! Let's connect your cameras.",
      subtitle:
        'ArcadianAI is how security monitoring should be: Simple, Affordable & Proactive. Scan you QR code to begin monitoring. ',
      dontHave: 'Don’t have cameras? ',
      infoTitle: 'The Cloud Subscription ',
      infoContent:
        'To fully utilize your AI Security cameras, a monthly or yearly subscription is required. This subscription grants you comprehensive access to all ArcadianAI streaming and monitoring services, unlimited AI heatmap plans, and options for 30, 60, or 90 days of cloud storage. Billing starts as soon as you connect your cameras to the platform.',
      camera: 'ArcadianAI'
    },
    drawer: {
      dontHave: 'Don’t have one? ',
      shop: 'Shop',
      byQRCode: 'QR',
      noCameraDevice: 'No cameras on this device',
      byActivationCode: 'Code',
      uploadImage: 'Upload',
      setupHelp: 'Setup Help',
      infoTitle: 'Why am I seeing this?',
      infoContent:
        "Regular software maintenance updates are vital to ensure your bridge and connected cameras operate smoothly. We've detected that the device in your possession is not compatible with our latest version. Rest assured, the necessary update will only require a few minutes of your time.",
      stayHere: 'Stay here. . . ',
      stayHereContent:
        "We're swiftly connecting your device to the cloud. Please make sure your bridge is powered on and within close proximity. This could take a few minutes.",
      areYouSure: 'Are you sure?',
      areYouSureContent:
        "Auto-discovery of your devices is in progress. Terminate this session by closing the window or clicking 'Cancel', and you'll lose all your work. Want to continue? Select 'Cancel Pairing' again.",
      areYouSureConnectingContent:
        "Connecting of your devices is in progress. Terminate this session by closing the window or clicking 'Cancel', and you'll lose all your work. Want to continue? Select 'Cancel Pairing' again.",
      cameraUsername: 'Camera Username',
      cameraPassword: 'Camera Password',
      discoverMore: 'Discover More',
      connectCameras: 'Connect Cameras',
      skipPassword: 'Skip, Input Passwords for Each Camera',
      updateAlertTitle: 'Why am I seeing this?',
      updateAlertContent:
        "Regular software maintenance updates are vital to ensure your bridge and connected cameras operate smoothly. We've detected that the device in your possession is not compatible with our latest version. Rest assured, the necessary update will only require a few minutes of your time.",
      installUpdate: 'Install Latest Software',
      releaseNote: 'Release Note ( {data} )',
      confirmCancel: 'Cancel Pairing',
      profile: {
        hardwareSpecs: 'Hardware Specs',
        deviceName: 'Device Name',
        deviceLocation: 'Device Location (Physical Address)',
        addTags: 'Add Tags',
        model: 'Model',
        brand: 'Brand',
        hardwareID: 'Hardware ID',
        software: 'Software',
        version: 'Version No.',
        connectedCameras: 'Connected Cameras',
        successUpdate: 'Bridge Updated successfully'
      },
      activation: {
        title: 'Let’s bridge the gap!',
        subtitle:
          'Keep your gear. Simply connect the Arcadian Bridge cloud connector to your modem and we will take care of the rest for you. ',
        addSuccess: 'Bridge Activated Successfully',
        notDetectedAlertTitle: 'Couldn’t locate your bridge',
        notDetectedContent:
          "It seems like your device may have had a momentary lapse. Let's get it back on track! Please ensure that your bridge is nearby and powered on"
      },
      cameras: {
        title: 'Found your bridge!',
        subtitle:
          "We've detected %{data} nearby. To streamline their connection, please share their usernames and passwords with us."
      },
      update: {
        title: 'Found your bridge!',
        subtitle:
          'hey, we’ve made a discovery—your hardware has been detected nearby. To ensure the connection process goes smoothly, it’s essential to update your cloud connector firmware for optimal results.',
        update: {
          requestUpdateSuccess: 'The update will take a while. You will be notified of the result.',
          updateSuccessfully: 'Your Bridge were updated successfully.'
        }
      },
      actions: {
        cancel: 'Cancel',
        connect: 'Connect'
      },
      connecting: {
        connectedSuccessfully: 'Your Bridge was connected successfully'
      }
    },
    status: {
      '0': 'Inactivate',
      '1': 'Connected',
      '2': 'Connecting...',
      '3': 'Disconnected',
      '4': 'Pending Update',
      needUpdate: 'Pending Update',
      installing: 'Installing an Update...'
    },
    menu: {
      editSetup: 'Edit Setup',
      edit: 'Edit',
      view: 'View',
      editBulk: 'Edit Bulk',
      autoDiscover: 'Auto-discover',
      forget: 'Forget Device',
      update: 'Update Available',
      updated: 'Up to date (v.{data})'
    },
    errorMessage: {
      notfound: 'Unrecognized QR Code. Try again or get in touch with our support team.',
      expiredTime: 'Expired Time',
      expiredTimeErrorMessage: "Ops we can't Detective your Bridge, please try again",
      cameraAddSuccess: 'Camera connected successfully',
      cameraConnectFail: 'No cameras added',
      internalservererror: 'Server Error 500',
      dontHaveActiveBridge: "You don't Have an Active bridge. Please go to the previous step",
      'code:isrequired': 'Error: QR code Invalid. Upload a clear image of your QR code.',
      agentactivationfailed: 'Unrecognized Code, Try again or get in touch with our support team.',
      errorDelete: 'Something went wrong...',
      loadDataFailed: 'Something went wrong in loading the data.',
      softwareUpdateFailed: 'The bridge software update failed.',
      softwareUpdateCanceled:
        'The bridge software update cancled, Please try again a few minutes later.'
    },
    messages: {
      successfulDelete: 'Done',
      updateSoftware: 'The bridge software update successfully.'
    },
    discoveryLoader: 'We are doing auto-discovery, please wait ...',
    onboarding: {
      title: 'Ensure your devices are powered on and nearby . 🔌',
      noticeTitle: 'Tips on Plug & Play ',
      noticeContent:
        "Hey! We didn’t spot any cameras, but some might be playing hard to get due to your device's extra security layer. No worries—you can still add them manually via Ports. Need a hand? Give us a shout at {supportNumber} or submit a {supportTicket} now.",
      supportTicket: 'Support Ticket',
      supportNumber: '+1 800 288 9192'
    },
    delete: {
      title:
        'Ready to wipe out {name} and its entire cloud collection (cameras, recordings, clips, and analytics)? Type in your name to make it official.',
      description:
        "By selecting 'Delete Device', I acknowledge that I am initiating an irreversible action. This will permanently disconnect Arcadian Orbit Inc.'s services for my device, leading to the immediate and irretrievable loss of all recorded footage and all associated hardware data.",
      deleteButton: 'Delete',
      inputLabel: 'Type {name}'
    }
  }
}
