import type { CameraCoreComposite } from '@/modules/Camera/interface'
import { CameraCore } from '@/modules/Camera/CameraCore'
import type { Camera as CameraObject } from '@/lib/api'

export class CameraBridgePivot implements CameraCoreComposite {
  public readonly bridgeId!: string | undefined
  public readonly id!: string

  constructor(public readonly core: CameraCore, cameraObject: CameraObject) {
    this.id = this.core.id
    this.bridgeId = cameraObject.bridgeId ? cameraObject.bridgeId : undefined
  }
}
