export enum ActiveBridgeStateType {
  activate = 1,
  discovering,
  cameras,
  notDetected,
  update,
  connecting
}

export enum BridgeDrawerMode {
  profile,
  create
}

export * from './libs/version-manager/version.interfaces'
