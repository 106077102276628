import type { CameraTemp, Location } from '@/modules/Camera/interface'
import { CameraStatusType } from '@/modules/Camera/types'
import { type TagEachResource } from '@/lib/api'
import { storeToRefs } from 'pinia'
import { useOnboardingStore } from '@/modules/Onboarding/store/useOnboardingStore'

export function generateUniqueId(): string {
  const time = new Date().getTime()
  const random = Math.floor(Math.random() * 1000000)
  return `${time}-${random}`
}

export interface CameraConfig {
  id?: string
  timezone?: string
  name: string
  groupId: string
  url?: string
  port?: number
  securePort?: number
  streamPort?: number
  extraPorts?: Array<number>
  username?: string
  password?: string
  channel?: number
  useSSL?: boolean
  manufacturerCode: number
  bridgeId?: string | undefined
  userData?: string
  createdAt?: Date | string
  updatedAt?: Date | string
  selectedTag?: TagEachResource[]
  location?: Location
  avatar?: string
}

export function useCreateCameraTempData(cameraConfiguration: CameraConfig): CameraTemp {
  const {
    name,
    groupId,
    url = '',
    port = 80,
    securePort = 0,
    streamPort = 554,
    extraPorts = [],
    username = '',
    password = '',
    channel = 1,
    useSSL = false,
    manufacturerCode,
    selectedTag = [],
    avatar = '',
    location = {
      longitude: -74.0072955,
      latitude: 40.7094756,
      fullAddress: '',
      markerId: ''
    },
    bridgeId,
    userData
  } = cameraConfiguration
  const cameraTemp = {
    cameraData: {
      name,
      groupId,
      bridgeId: bridgeId ? bridgeId : undefined,
      config: {
        url,
        port,
        securePort,
        streamPort,
        extraPorts,
        username,
        password,
        channel,
        useSSL,
        manufacturerCode
      },
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      userData: userData || generateUniqueId()
    },
    tags: selectedTag,
    avatar,
    status: CameraStatusType.offline,
    location
  }
  return cameraTemp
}

export function removeCameraInStore(userData: string | Array<string>) {
  function drop(id: string) {
    const { data } = storeToRefs(useOnboardingStore())
    if (data.value.camera.cameraList) {
      const index = data.value.camera.cameraList.findIndex((e) => e.cameraData.userData === id)
      if (index !== -1) {
        data.value.camera.cameraList.splice(index!, 1)
      }
      removeCameraSelection(id)
    }
  }

  if (Array.isArray(userData)) {
    userData.forEach(drop)
  } else {
    drop(userData)
  }
}

export function removeCameraSelection(id?: string) {
  const { data } = storeToRefs(useOnboardingStore())
  if (data.value.camera.cameraList) {
    const index = data.value.camera.selections?.findIndex((e) => e.cameraData.userData === id)
    if (index !== -1) {
      data.value.camera.selections?.splice(index!, 1)
    }
  }
}
