import { Service } from '../../service'
import type { CreateExportDTO, ExportData } from '@/lib/api'
import type { ExportRequest } from './export.interface'

export class ExportService extends Service {
  public create(data: CreateExportDTO) {
    return this.post<ExportData>(`/api/export-manager/legacy/export`, data)
  }

  public findAll(cameraId: string) {
    return this.get<ExportData[]>(
      `/api/export-manager/legacy/export?cameraId=${cameraId}&detailed=true`
    )
  }

  public findOne(id: string) {
    return this.get<ExportData>(`/api/export-manager/legacy/export/${id}`)
  }

  public update(id: string, data: any) {
    return this.patch<any, ExportData>(`/api/export-manager/legacy/export/${id}`, data)
  }

  public remove(id: string) {
    return this.delete<void>(`/api/export-manager/legacy/export/${id}`)
  }

  public download(id: string, callback?): Promise<Blob> {
    return this.adaptor.get(`/api/export-manager/legacy/export/${id}/media`, {
      responseType: 'blob',
      onDownloadProgress: (progressEvent: any) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        if (callback) {
          callback(percentCompleted)
        }
      }
    })
  }
  public downloadThumbnail(id: string, callback?) {
    return this.adaptor.get(`/api/export-manager/legacy/export/${id}/thumbnail/media`, {
      responseType: 'blob',
      onDownloadProgress: (progressEvent: any) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        if (callback) {
          callback(percentCompleted)
        }
      }
    })
  }

  // * Export manager - Bridge
  exportEdgeStorage(id: string, data: ExportRequest) {
    return this.post(`/api/camera-manager/camera/${id}/export-edge-storage`, data)
  }
}
