import type { PlayerCore } from '@/player/lib/player/player-core'
import type { PointDto } from '@/lib/api/services/ai/analytics/analytic.interface'
import { AnalyticEventTypes } from '@/lib/api'

export class AnalyticService {
  constructor(protected readonly core: PlayerCore) {}

  async updateMotion(id: string, name: string, minThreshold: number, sensitivity: number) {
    const ref = this.core.analyticManager2.getAnalyticById(id)
    const data = await this.core.apis.updateAnalytics(id, {
      type: AnalyticEventTypes.SwMotion,
      config: {
        sensitivity,
        points: ref?.ref?.configs?.config?.points || [
          { x: 0, y: 0 },
          { x: 1, y: 0 },
          { x: 1, y: 1 },
          { x: 0, y: 1 }
        ],
        minThreshold,
        alarmDelay: 0
      },
      features: {
        name
      }
    })
    this.core.analyticManager2.updateAnalyticRef(id, data.item)
  }

  async createMotion(name: string, minThreshold: number, sensitivity: number, points: PointDto[]) {
    const data = await this.core.apis.createAnalytics({
      type: AnalyticEventTypes.SwMotion,
      config: {
        sensitivity,
        points,
        minThreshold,
        alarmDelay: 0
      },
      features: {
        name
      }
    })
    this.core.analyticManager2.addAnalytic(data.item)
  }

  async removeMotion(id: string) {
    await this.core.apis.deleteAnalytics(id)
    this.core.analyticManager2.removeAnalyticById(id)
  }

  async updateFence(id: string, minThreshold: number, sensitivity: number, name: string) {
    const ref = this.core.analyticManager2.getAnalyticById(id)
    if (!ref?.ref?.configs?.config?.points) {
      throw new Error()
    }
    const data = await this.core.apis.updateAnalytics(id, {
      type: AnalyticEventTypes.SwFence,
      config: {
        sensitivity,
        points: ref.ref.configs.config.points,
        minThreshold,
        alarmDelay: 0
      },
      features: {
        name: name || ref?.ref?.configs.features?.name || 'Entry/Exit Point'
      }
    })
    this.core.analyticManager2.updateAnalyticRef(id, data.item)
  }

  async createFence(minThreshold: number, sensitivity: number, points: PointDto[], name: string) {
    const data = await this.core.apis.createAnalytics({
      type: AnalyticEventTypes.SwFence,
      config: {
        sensitivity,
        points,
        minThreshold,
        alarmDelay: 0
      },
      features: {
        name
      }
    })
    this.core.analyticManager2.addAnalytic(data.item)
  }

  async removeFence(id: string) {
    await this.core.apis.deleteAnalytics(id)
    this.core.analyticManager2.removeAnalyticById(id)
  }
}
