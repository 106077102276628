import { PermissionsOrganizer } from './ComponentPermissionsManager'

let permissionsOrganizer: null | PermissionsOrganizer = null

export function usePermissionsOrganaizer() {
  if (!permissionsOrganizer) {
    permissionsOrganizer = new PermissionsOrganizer()
    return permissionsOrganizer
  } else return permissionsOrganizer
}
