import { BridgeService, BridgeStatus } from '@/lib/api'
import { useServices } from '@/lib/services'
import { BridgeStatusBackgroundService } from '@/modules/Bridge/libs/status-manager/BridgeStatusBackgroundService'
import { debounce } from 'lodash'
import { ref, type Ref } from 'vue'

export class BridgeStatusManager {
  public status: Ref<BridgeStatus> = ref(BridgeStatus.stall)
  protected service: BridgeService = useServices().bridge
  protected static updater = new BridgeStatusBackgroundService()
  constructor(public readonly id: string, status: BridgeStatus) {
    this.setStatus(status)
    BridgeStatusManager.updater.register(this)
  }

  public async checkBridgeStatus(maxAttempts = 60, interval = 2000) {
    let attempts = 0
    while (attempts < maxAttempts) {
      try {
        this.setStatus(await this.fetchStatus())
        if (this.status.value === BridgeStatus.active) {
          return this.status.value
        }
        await new Promise((resolve) => setTimeout(resolve, interval))
        attempts++
      } catch (error) {
        if (attempts === 0) {
          await new Promise((resolve) => setTimeout(resolve, interval))
          attempts++
        } else {
          console.error('Error checking bridge status:', error)
          break
        }
      }
    }
    throw new Error('Maximum attempts reached without active status')
  }

  private fetchDebounce = debounce(async () => {
    const { status } = await this.service.checkStatus(this.id)
    this.setStatus(status)
    return status
  }, 500)

  public async fetchStatus() {
    return (await this.fetchDebounce()) || BridgeStatus.disconnected
  }

  unregisterForUpdater() {
    BridgeStatusManager.updater.unregister(this)
  }

  public setStatus(status: BridgeStatus) {
    this.status.value = status
  }
}
