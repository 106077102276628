export default {
  monitoring: {
    title: 'Monitoring Dashboard',
    messages: {
      looking: "I'm looking for...",
      aiDetectionTitle: 'AI Detection Search',
      ExpandTooltip: 'Expand',
      snapShotTooltip: 'Take Snapshots',
      MinimizeTooltip: 'Minimize',
      aiDetection:
        'our search parameters for regular alerts. Elevate your surveillance strategy, embracing precision and proactive oversight.',
      aiHeatMapTitle: 'AI Heatmaps',
      aiHeatMap:
        'are not just tools — they are strategic assets designed to drive revenue for your growing businesses. Harnessing visual intelligence, AI Heatmaps sharpen space dynamics, converting raw footage into pivotal insights on traffic patterns, hotspots, unwanted visitors, stressful zones, and customer behaviors.',
      clock:
        'Rewind Multiple Cameras to Date: Easily sync playback timelines across cameras to a specific date.',
      aiHeader: 'Events:',
      aiDescription:
        'Coming soon: X-Ray vision for your space! Easily discern familiar faces from unknown visitors, amplifying loss prevention alerts in your security arsenal.',
      gradiantHeader: 'AI Heatmaps:',
      gradiantDescription:
        'AI Heatmaps Preview: Experience your raw footage converting into activity hotspots, unearthing new revenue-generating possibilities in your busiest zones',
      gradiantDescriptionDisabled:
        "Exciting news: You're on the early access list for AI Heatmaps! Transform raw footage into revealing insights about customer behavior, preventing losses. To find out release dates, schedule a demo at sales{'@'}arcadian.ai.",
      box: 'Puzzle View',
      list: 'List View',
      successfulRemoveClip: 'Deleted!'
    },
    welcome: 'Welcome {name} !',
    dive: "Ready to roll? You're on the brink of the Cloud ☁️",
    trialEnded:
      'Continue enjoying uninterrupted peace of mind. Ensure your workspace remains secure by updating your payment method.',
    clips: 'Clips',
    heatmap: 'Heatmap',
    until: 'Until',
    RemainingBalance: 'Remaining Balance',
    balanceTooltip:
      "Seeing a balance? It's due to having fewer connected devices on your plan now. This amount will be credited to reduce your upcoming bill. Enjoy!",
    since: 'Since',
    type: {
      maps: 'Maps',
      cameras: 'Cameras',
      connectors: 'Connectors',
      error: 'Error'
    },
    table: {
      header: {
        device: 'Device',
        status: 'Status',
        location: 'Location',
        people: 'People',
        tag: 'Tags'
      },
      actions: {
        profile: 'Profile',
        clone: 'Duplicate',
        remove: 'Forget Device'
      }
    },
    actions: {
      learning: 'Get Setup Assistance Here🚀',
      back: 'Back to Onboarding',
      live: 'Live',
      all: 'All',
      recording: 'REC',
      error: 'Offline',
      tryAgain: 'Try Again',
      connecting: 'Connecting...',
      offline: 'Offline',
      disconnected: 'Disconnected',
      updatePayment: 'Update Payment',
      addPayment: '+ Payment Method',
      Downloading: 'Downloading...',
      LiveURLs: 'Live URLs',
      Archived: 'Archived',
      SortByNew: 'Sort By: New',
      cloudConnect: 'Cloud Connectors',
      AIDetect: 'Orbit, The AI Detection '
    },
    ads: {
      title: 'Frequently Asked Question',
      question: '{q}: Who has access to my video footage?',
      answer:
        '{a}: Unequivocally, no one. Your footage is fortified with {encryption} and is securely anchored on the Amazon AWS cloud, leveraging the pinnacle of encryption technologies. Your privacy is paramount to us.',
      encryption: 'end-to-end encryption'
    },
    remove: {
      title: 'Taking out {title} means its stored recordings and analytics will be wiped clean.',
      action: 'Delete Camera'
    },
    clipSection: {
      title: 'Incident Insights',
      content:
        'Dash to your monitoring and grab the standout events from your players. Your footage, once downloaded, is sorted into clips for hassle-free access and future reference!'
    },
    heatmapSection: {
      title: 'Data, Data, Novel Untapped Data',
      content:
        'Unlock the potential of your surveillance data with AI heatmaps, revealing key insights like peak activity times, customer dwell times between aisles, and checkout line efficiency.'
    },
    tooltips: {
      clipDownload: 'Download Now!',
      clipRemove: 'Permanently Delete File'
    }
  }
}
